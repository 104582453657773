
import {
    defineComponent, PropType, ref, computed,
} from 'vue';
import Location from '@/domain/Location';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import coreStore from '@/store/CoreStore';
import LocationType, { StaticLocationType } from '@/domain/LocationType';
import { ValidationResult } from '@/validation/types';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import DateInput from '@/components/inputs/DateInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import NonWorkingTimeSet from '@/domain/Dates/NonWorkingTimeSet';
import FreightCode from '@/domain/FreightCode';
import Language from '@/domain/Language';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import NumberInput from '@/components/inputs/NumberInput.vue';
import { formatMilitaryTimeFromMinutes } from '@/functions/date';
import FormMultiSelect from '@/components/FormMultiSelect.vue';

export default defineComponent({
    name: 'location-form',
    components: {
        NumberInput,
        BCol,
        BForm,
        BFormInput,
        BFormSelect,
        BRow,
        BSpinner,
        CheckboxInput,
        DateInput,
        SelectInput,
        TextInput,
        FormMultiSelect,
    },
    props: {
        modelValue: {
            type: Object as PropType<Location>,
            required: true,
        },
        validationResult: {
            type: Object as PropType<ValidationResult<Location>>,
            required: true,
        },
        readonly: { type: Boolean, default: () => false },
        loading: { type: Boolean, default: () => false },
        allLocations: {
            type: Array as PropType<Array<Location>>,
            default: () => [],
        },
    },
    emits: ['update:modelValue'],
    setup(props) {
        const { configStore } = masterDataStore.getInstance();
        const maxNumOfMinsForTimeslot = 2 * 60;
        const { timeSetStore, languageStore } = coreStore.getInstance();
        const timeslotDurationOptions: { text: string, value: number }[] = [];

        const show = ref(true);

        for (let i = 15; i <= maxNumOfMinsForTimeslot; i += 15) {
            timeslotDurationOptions.push({
                text: formatMilitaryTimeFromMinutes(i),
                value: i,
            });
        }

        function changeLocationType(value: LocationType) {
            props.modelValue.type = value;
            props.modelValue.typeId = value.id;
        }

        function changeLocationNonWorkDayCalendar(value: NonWorkingTimeSet) {
            props.modelValue.nonWorkingTimeSet = value;
            props.modelValue.nonWorkingTimeSetId = value.id;
        }

        function changeFreightCode(value: FreightCode) {
            props.modelValue.freightCode = value;
            props.modelValue.freightCodeId = value?.id;
        }

        function changeLanguage(value: Language) {
            props.modelValue.language = value;
        }

        function changeTimeslotDuration(value: { text: string, value: number } | null) {
            if (value) {
                props.modelValue.timeslotDuration = value.value;
            } else {
                props.modelValue.timeslotDuration = undefined;
            }
        }

        const validLocationTypes = computed(() => configStore.locationTypes.filter((lt) => lt.staticTypeIdentifier !== StaticLocationType.BuiltIn));

        return {
            configStore,
            show,
            changeLocationType,
            timeSetStore,
            changeLocationNonWorkDayCalendar,
            changeFreightCode,
            languageStore,
            changeLanguage,
            changeTimeslotDuration,
            getTitleCaseTranslation,
            getTranslation,
            timeslotDurationOptions,
            validLocationTypes,
        };
    },
});
