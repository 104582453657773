
import { computed, defineComponent, reactive } from 'vue';
import Location from '@/domain/Location';
import { StaticLocationType } from '@/domain/LocationType';
import { TableDefinition } from '@/types';
import LocationService from '@/services/LocationService';
import Screen from '@/components/layout/Screen.vue';
import router from '@/router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import LocationForm from '@/modules/master-data/views/locations/components/LocationForm.vue';
import useValidator from '@/validation/useValidator';
import Permissions from '@/services/permissions/Permissions';
import CoreStore from '@/store/CoreStore';
import EntityType from '@/domain/enums/EntityTypes';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

type State = {
    newLocation: Location;
    showForm: boolean;
    loading: boolean;
};

export default defineComponent({
    name: 'locations-screen',
    components: {
        Screen,
        LocationForm,
    },
    setup() {
        const locationService = new LocationService();
        const { locationStore } = masterDataStore.getInstance();
        const nonWorkingTimeSetStore = CoreStore.getInstance().timeSetStore;

        const { validateForm, validationResult, clearResults } = useValidator<Location>('new-location');

        const state = reactive<State>({
            newLocation: new Location(),
            showForm: false,
            loading: false,
        });

        async function save() {
            state.loading = true;
            validateForm(state.newLocation);
            if (validationResult.isValid) {
                const response = await locationService.addNewLocation(state.newLocation);
                if (response.success) {
                    state.showForm = false;
                    await router.push({
                        name: MasterDataRouteTypes.LOCATION.EDIT,
                        params: { locationId: response.newLocId.toString() },
                    });
                }
            }
            state.loading = false;
        }

        const table = computed(
            (): TableDefinition<Location> => ({
                items: locationStore.locations.filter((loc) => loc.type.staticTypeIdentifier !== StaticLocationType.BuiltIn),
                key: 'locationList',
                name: getTitleCaseTranslation('core.domain.locations'),
                columnDefinition: [
                    {
                        label: getTitleCaseTranslation('core.domain.locationNumber'),
                        key: 'locationNumber',
                        searchable: true,
                        width: '23%',
                    },
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                        searchable: true,
                        width: '31%',
                    },
                    {
                        key: 'shortName',
                        label: getTitleCaseTranslation('core.domain.shortName'),
                        searchable: true,
                        width: '23%',
                    },
                    {
                        key: 'typeDescription',
                        label: getTitleCaseTranslation('core.domain.locationType'),
                        searchable: true,
                        width: '23%',
                    },
                ],
            }),
        );

        function openAdd() {
            clearResults();
            state.newLocation = new Location();
            state.newLocation.nonWorkingTimeSet = nonWorkingTimeSetStore.defaultTimeSet;
            state.newLocation.nonWorkingTimeSetId = state.newLocation.nonWorkingTimeSet?.id;
            state.showForm = true;
        }

        function openEdit(loc: Location) {
            clearResults();
            router.push({
                name: MasterDataRouteTypes.LOCATION.EDIT,
                params: { locationId: loc.id.toString() },
            });
        }

        async function openLocationHistory(location: Location) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.LOCATION, entityId: location.id },
            });
        }

        return {
            state,
            validationResult,
            table,
            openAdd,
            openEdit,
            openLocationHistory,
            save,
            getTitleCaseTranslation,
            getTranslation,
            Permissions,
        };
    },
});
