import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!

  return (!_ctx.loading)
    ? (_openBlock(), _createBlock(_component_b_form, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_text_input, {
                modelValue: _ctx.modelValue.locationNumber,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.locationNumber) = $event)),
                label: _ctx.getTitleCaseTranslation('core.domain.locationNumber'),
                md: "3",
                loading: _ctx.loading,
                readonly: _ctx.readonly,
                error: _ctx.validationResult?.model.locationNumber,
                class: "form-col",
                "more-info": _ctx.getTranslation('core.common.uniqueIdentifierForThisLocation')
              }, null, 8, ["modelValue", "label", "loading", "readonly", "error", "more-info"]),
              _createVNode(_component_text_input, {
                modelValue: _ctx.modelValue.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.name) = $event)),
                label: _ctx.getTitleCaseTranslation('core.domain.name'),
                md: "3",
                loading: _ctx.loading,
                readonly: _ctx.readonly,
                error: _ctx.validationResult?.model.name,
                class: "form-col",
                autofocus: ""
              }, null, 8, ["modelValue", "label", "loading", "readonly", "error"]),
              _createVNode(_component_text_input, {
                modelValue: _ctx.modelValue.shortName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.shortName) = $event)),
                label: _ctx.getTitleCaseTranslation('core.domain.shortName'),
                md: "3",
                loading: _ctx.loading,
                readonly: _ctx.readonly,
                error: _ctx.validationResult?.model.shortName,
                class: "form-col"
              }, null, 8, ["modelValue", "label", "loading", "readonly", "error"]),
              _createVNode(_component_select_input, {
                model: _ctx.modelValue.type.id,
                label: _ctx.getTitleCaseTranslation('core.domain.locationType'),
                options: _ctx.validLocationTypes,
                text: "description",
                value: "id",
                loading: _ctx.loading,
                readonly: _ctx.readonly,
                error: _ctx.validationResult?.model.type,
                md: "3",
                class: "form-col",
                onChange: _cache[3] || (_cache[3] = (value) => _ctx.changeLocationType(value))
              }, null, 8, ["model", "label", "options", "loading", "readonly", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_select_input, {
                model: _ctx.modelValue.nonWorkingTimeSet?.id,
                label: _ctx.getTranslation('core.domain.nonWorkDaysCalendar'),
                options: _ctx.timeSetStore.allTimeSets,
                text: "name",
                value: "id",
                loading: _ctx.loading,
                readonly: _ctx.readonly,
                md: "3",
                class: "form-col",
                error: _ctx.validationResult?.model.nonWorkingTimeSetId,
                onChange: _cache[4] || (_cache[4] = (value) => _ctx.changeLocationNonWorkDayCalendar(value))
              }, null, 8, ["model", "label", "options", "loading", "readonly", "error"]),
              _createVNode(_component_select_input, {
                model: _ctx.modelValue.freightCode?.id,
                label: _ctx.getTitleCaseTranslation('core.domain.freightCode'),
                options: _ctx.configStore.freightCodes,
                text: "description",
                value: "id",
                loading: _ctx.loading,
                readonly: _ctx.readonly,
                md: "3",
                class: "form-col",
                "allow-empty-option": "",
                onChange: _cache[5] || (_cache[5] = (value) => _ctx.changeFreightCode(value))
              }, null, 8, ["model", "label", "options", "loading", "readonly"]),
              _createVNode(_component_text_input, {
                modelValue: _ctx.modelValue.taxId,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelValue.taxId) = $event)),
                label: _ctx.getTitleCaseTranslation('core.domain.taxId'),
                md: "3",
                loading: _ctx.loading,
                readonly: _ctx.readonly,
                error: _ctx.validationResult?.model.taxId,
                class: "form-col"
              }, null, 8, ["modelValue", "label", "loading", "readonly", "error"]),
              _createVNode(_component_b_col, { md: "3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.modelValue.targetDaysOnHand,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modelValue.targetDaysOnHand) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.targetDaysOnHandOverride'),
                    number: "",
                    readonly: _ctx.readonly,
                    error: _ctx.validationResult?.model.targetDaysOnHand,
                    class: "form-col"
                  }, null, 8, ["modelValue", "label", "readonly", "error"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_checkbox_input, {
                modelValue: _ctx.modelValue.isOpen24Hours,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modelValue.isOpen24Hours) = $event)),
                label: _ctx.getTitleCaseTranslation('core.domain.open24Hours'),
                md: "3",
                disabled: _ctx.readonly,
                error: _ctx.validationResult?.model.isOpen24Hours,
                "inline-right": "",
                class: "form-col"
              }, null, 8, ["modelValue", "label", "disabled", "error"]),
              (!_ctx.modelValue.isOpen24Hours)
                ? (_openBlock(), _createBlock(_component_date_input, {
                    key: 0,
                    modelValue: _ctx.modelValue.openTime,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modelValue.openTime) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.openTime'),
                    md: "3",
                    disabled: _ctx.readonly || _ctx.modelValue.isOpen24Hours,
                    "input-format": "H:mm",
                    "starting-view": "time",
                    "minimum-view": "time",
                    error: _ctx.validationResult?.model.openTime,
                    class: "form-col"
                  }, null, 8, ["modelValue", "label", "disabled", "error"]))
                : _createCommentVNode("", true),
              (!_ctx.modelValue.isOpen24Hours)
                ? (_openBlock(), _createBlock(_component_date_input, {
                    key: 1,
                    modelValue: _ctx.modelValue.closeTime,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modelValue.closeTime) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.closeTime'),
                    md: "3",
                    disabled: _ctx.readonly || _ctx.modelValue.isOpen24Hours,
                    "input-format": "H:mm",
                    "starting-view": "time",
                    "minimum-view": "time",
                    error: _ctx.validationResult?.model.closeTime,
                    class: "form-col"
                  }, null, 8, ["modelValue", "label", "disabled", "error"]))
                : _createCommentVNode("", true),
              _createVNode(_component_select_input, {
                model: _ctx.modelValue.language?.id,
                label: _ctx.getTitleCaseTranslation('core.domain.language'),
                options: _ctx.languageStore.languages,
                text: "name",
                value: "id",
                loading: _ctx.loading,
                readonly: _ctx.readonly,
                md: "3",
                class: "form-col",
                "allow-empty-option": "",
                onChange: _cache[11] || (_cache[11] = (value) => _ctx.changeLanguage(value))
              }, null, 8, ["model", "label", "options", "loading", "readonly"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { md: "3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_number_input, {
                    modelValue: _ctx.modelValue.equipmentInspectionInterval,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modelValue.equipmentInspectionInterval) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.equipmentInspectionInterval'),
                    disabled: _ctx.readonly,
                    error: _ctx.validationResult?.model.equipmentInspectionInterval,
                    class: "form-col",
                    required: false
                  }, null, 8, ["modelValue", "label", "disabled", "error"])
                ]),
                _: 1
              }),
              _createVNode(_component_b_col, { md: "3" }, {
                default: _withCtx(() => [
                  _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.processedPutAwayPullFrom')), 1),
                  _createVNode(_component_b_form_select, {
                    modelValue: _ctx.modelValue.processedPutAwayPullFromLocationId,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.modelValue.processedPutAwayPullFromLocationId) = $event)),
                    options: _ctx.allLocations,
                    "text-field": "name",
                    "value-field": "id",
                    readonly: _ctx.readonly,
                    class: "form-col"
                  }, null, 8, ["modelValue", "options", "readonly"])
                ]),
                _: 1
              }),
              _createVNode(_component_b_col, { md: "3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_form_multi_select, {
                    modelValue: _ctx.modelValue.billToLocations,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.modelValue.billToLocations) = $event)),
                    data: _ctx.allLocations,
                    "search-by": "name",
                    label: _ctx.getTitleCaseTranslation('core.domain.billTo'),
                    loading: _ctx.loading,
                    class: "display-on-top"
                  }, null, 8, ["modelValue", "data", "label", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.modelValue.isServiceCenter)
            ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_select_input, {
                    model: _ctx.modelValue.timeslotDuration,
                    "allow-empty-option": "",
                    class: "form-col",
                    label: _ctx.getTitleCaseTranslation('core.domain.timeslotDuration'),
                    loading: _ctx.loading,
                    md: "3",
                    options: _ctx.timeslotDurationOptions,
                    readonly: _ctx.readonly,
                    text: "text",
                    value: "value",
                    onChange: _ctx.changeTimeslotDuration
                  }, null, 8, ["model", "label", "loading", "options", "readonly", "onChange"]),
                  _createVNode(_component_b_col, { md: "3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_input, {
                        modelValue: _ctx.modelValue.maxLoadPerTimeslot,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.modelValue.maxLoadPerTimeslot) = $event)),
                        class: "form-col",
                        disabled: _ctx.readonly,
                        error: _ctx.validationResult?.model.maxLoadPerTimeslot,
                        label: _ctx.getTitleCaseTranslation('core.domain.maxLoadPerTimeslot'),
                        type: "number"
                      }, null, 8, ["modelValue", "disabled", "error", "label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_b_spinner, { key: 1 }))
}