import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_form = _resolveComponent("location-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showForm,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.showForm) = $event)),
        centered: "",
        size: "xl",
        title: _ctx.getTitleCaseTranslation('core.domain.newLocation')
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            class: "submit-btn",
            variant: "primary",
            disabled: _ctx.state.loading,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_b_spinner, { class: "submit-spinner" }, null, 512), [
                [_vShow, _ctx.state.loading]
              ]),
              _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')), 513), [
                [_vShow, !_ctx.state.loading]
              ])
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          (_ctx.state.showForm)
            ? (_openBlock(), _createBlock(_component_location_form, {
                key: 0,
                modelValue: _ctx.state.newLocation,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.newLocation) = $event)),
                "validation-result": _ctx.validationResult
              }, null, 8, ["modelValue", "validation-result"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        "dynamic-columns": ""
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            disabled: !_ctx.Permissions.ADMINISTRATION.canEditLocations(),
            "disabled-message": _ctx.getTranslation('core.common.youDoNotHavePermissionToAddLocations'),
            onClick: _ctx.openAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addLocation')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "disabled-message", "onClick"])
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                disabled: !_ctx.Permissions.ADMINISTRATION.canEditLocations(),
                "disabled-message": _ctx.getTranslation('core.common.youDoNotHavePermissionToEditLocations'),
                onClick: () => _ctx.openEdit(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "disabled-message", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                onClick: () => _ctx.openLocationHistory(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["table-array"])
    ]),
    _: 1
  }))
}